import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
    MDBRadio, MDBBtnGroup, MDBBtn,
    MDBBadge, MDBListGroup, MDBListGroupItem

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();
    const [autoCompleteoption, setAutoCompleteoption] = useState([]);
    const { t } = useTranslation('translations');
    const [userprofile, setUserprofile] = useState(props.userprofile);
    const handleSubmit = (e) => {
        e.preventDefault();

        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }

        if (!items.gender)
            error.current.showerror("No gender")

        if (!items.dob)
            error.current.showerror("No dob")

        items.age = window.calculateAge(items.dob);

        props.callback?.apply(this, [items]);

    };
    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res);
            getTopicList(res);
        } catch (e) {
            error.current.showerror(e);
        }

    }

    async function getTopicList(profile) {
        if (!profile) return;
        try {
            var page = await services.getTopicList(profile)
            if (page) {
                setAutoCompleteoption(page.items.filter(e => e.checkup == "Y"));


            }
        } catch (e) {
            error.current.showerror(e);
        }

    }
    useEffect(() => {
        if (props.self)
            fetchuserprofile();
        else
            getTopicList(userprofile);

    }, []);
    async function nextPage(answer, title, topic_id) {

        navigate("/" + answer + (props.self ? "/self" : ""), { state: { profile: userprofile, title: title, topic_id: topic_id } })

    }
    async function nextPagesymptomchecker(answer) {
        var page = await services.getTopicList(userprofile)
        var selecteddestination = await services.getDestination(page.destinations, userprofile, [{
            topiclist: answer
        }])
        navigate("/symptomchecker" + "/" + answer + (props.self ? "/self" : ""), { state: { profile: userprofile, destination: selecteddestination, checkup: "Y" } })

    }
    return (
        <div className="container-fluid col col-md-6 text-start">
            <div className="list-group">
                {userprofile?.age < 18 && <><h6 className='bg-light p-2 border-top border-bottom text-danger text-center'>{t("no related checkup for current age")}</h6></>}
                {userprofile && userprofile?.age >= 18 && autoCompleteoption.some(el => el.id == 57) &&
                    <a href={"/checkup2/minimal/6" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup2/minimal/6", "Quick health check", 57)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Quick health check")}<MDBBadge pill className="ms-2 gold"><MDBIcon far icon="thumbs-up" color="dark" /></MDBBadge></h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Quick health check description")}</p>
                    </a>}

                {userprofile && userprofile?.age >= 18 && autoCompleteoption.some(el => el.id == 53) &&
                    <a href={"/checkup2/minimal/2" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup2/minimal/2", "Holistic assessment", 53)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Holistic assessment")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Holistic assessment description")}</p>
                    </a>}
                {userprofile && userprofile?.age >= 18 && autoCompleteoption.some(el => el.id == 47) &&
                    <a href={"/checkup/full" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup/full", "Checkup")}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Checkup")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Adult checkup description")}</p>
                    </a>}


                {userprofile && userprofile?.age >= 18 &&
                    <a href={"/symptomchecker/2" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPagesymptomchecker(2)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Vacination")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Adult vaccination description")}</p>
                    </a>}

                {/* {userprofile && userprofile?.age >= 10 &&
                    <a href={"/symptomchecker/27" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPagesymptomchecker(27)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Depression assessment (9Q)")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Depression assessment (9Q) description")}</p>
                    </a>} */}
                    
                {userprofile && userprofile?.age >= 18 && autoCompleteoption.some(el => el.id == 54) &&
                    <a href={"/checkup2/minimal/4" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup2/minimal/4", "Basic symptoms checker", 54)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Basic symptoms checker")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Basic symptoms checker description")}</p>
                    </a>}

                {userprofile && userprofile?.age >= 18 && autoCompleteoption.some(el => el.id == 55) &&
                    <a href={"/checkup2/minimal/5" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup2/minimal/5", "Basic symptoms checker", 55)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Basic symptoms checker 2")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Basic symptoms checker description")}</p>
                    </a>}

                {/* {userprofile &&
                    <a href={"/checkup2/minimal/3" + (props.self ? "/self" : "")} className="list-group-item list-group-item-action"
                        onClick={(ev) => ev.preventDefault() | nextPage("checkup2/minimal/3", "Underwrite", 50)}
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{t("Underwrite")}</h5>
                            <MDBIcon fas icon="angle-right fa-2x text-primary" />
                        </div>
                        <p className="mb-1 small text-muted">{t("Underwrite description")}</p>
                    </a>} */}
            </div>
        </div>
    );
});



export default component;